
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import SideNavigationSection from '~/components/layout/sideNavigation/SideNavigationSection.vue';
import OverflowList from '~/components/layout/list/OverflowList/OverflowList.vue';
import GCNavigationMixin from '~/configurations/mixins/GCNavigationMixin'
import NLink from '~/components/utils/NLink.vue';

export default {
  name: 'GCNavigationSide',
  components: {NLink, OverflowList, SideNavigationSection},
  mixins: [GCNavigationMixin],
  inject: ['getLoc'],
  props: {},
  data() {
    return {
      navigationTree: []
    }
  },

  watch: {
    '$route': {
      immediate: true,
      handler() {
        this.remapNavigation()
      }
    },

  },
  methods: {
    isActive(activeObject, item) {
      const routeName = this.$route.name.includes(item.url)
      let routeParams = true

      if('params' in item && !isEmpty(item.params)) {
        routeParams = isEqual(this.$route.params, item.params)
      }

      return routeName && routeParams
    }
  }
}
