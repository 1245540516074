
  import isEmpty from 'lodash/isEmpty'
  import OverflowListItem from '@/components/layout/list/OverflowList/OverflowListItem'
  import NLink from '~/components/utils/NLink'


  export default {
    name: 'OverflowList',
    components: { NLink, OverflowListItem },
    inject: ['getLoc'],
    props: {
      items: {
        type: [Array, Object],
        default: () => []
      },
      showChildren: Boolean,
      activeObject: {
        type: Object,
        required: true
      },
      activeArray: {
        type: Array
      },
      links: Boolean,
      comparisonAction: {
        type: Function,
        default: (activeObject, item, activeArray) => {
          if (activeArray) {
            return activeArray.includes(`${item.id}`) || activeArray.includes(item.id)
          }
          return activeObject.itemId === item.id
        }
      },
      detailComparisonAction: {
        type: Function,
        default: (activeObject, item, activeArray) => {
          if (activeArray) {
            return activeArray.includes(item.id)
          }
          return activeObject.subItemId === item.id
        }
      }
    },
    data () {
      return {}
    },
    methods: {
      gotClicked (id, subId = null, item = null) {
        this.$emit('selected', { itemId: id, subItemId: subId, item })
      },
      objectHasContent (object = {}) {
        return !isEmpty(object)
      }
    }
  }
