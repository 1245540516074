
  import DiwWrapper from '~/components/general/wrappers/DiwWrapper.vue'

  export default {
    name: 'OverflowListItem',
    components: {DiwWrapper},
    props: {
      label: {
        type: String
      },
      isActive: {
        type: Boolean,
        default: true
      },
      hasChildren: Boolean,
      to: [Object, String],
      link: Boolean
    },
    data () {
      return {}
    }
  }
